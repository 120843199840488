import { Banner, BannerLoader, DiscountConfiguration } from '@segunosoftware/storefront-banner';
import '@segunosoftware/storefront-banner/dist/styles.css';
import Account from './account';
import AutoApply from './auto-apply';
import Cart from './cart';
import Customer from './customer';
import Discount from './discount';
import Logger from './logger';
import ConversionTracker from './order/conversion-tracker';
import Storage from './storage';
import VersionCache from './version-cache';
// import Visit from './visit';
// import EmailStorage from './email/email-storage';
// import EmailFinder from './email/email-finder';
// import emailEvents from './email/events';
// import Dispatcher from './dispatcher';

// const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;
const autoApply = new AutoApply();
const versionCache = new VersionCache();
const cartLoader = new Cart();
const banner = new Banner(new Storage(true));
const customer = new Customer();
const COLLECTIONS_VERSION_KEY = 'collections';

const loadBackupDiscount = (configuration, bannerLoader, discountConfigurationCallback) => {
	if (configuration.getBackupEnabled()) {
		const backupConfigurationCallback = (discountCode, discountConfiguration, tier, tierConfigs, isError) => {
			discountConfigurationCallback(
				discountCode,
				new DiscountConfiguration({
					...discountConfiguration.config,
					type: configuration.getBackupType(),
					startsAt: configuration.getCustomStartsAt() || discountConfiguration.getStartsAt(),
					endsAt: configuration.getCustomEndsAt() || discountConfiguration.getEndsAt(),
					products: configuration.isCustomDisplayProducts() && discountConfiguration.getProducts()
				}),
				tier,
				tierConfigs,
				true,
				isError
			);
		};
		if (configuration.getBackupType() === 'discount') {
			const totalTiers = configuration.getTotalTiers();
			if (totalTiers > 0) {
				let currentTier = 0;
				let earnedDiscountConfiguration = null;
				let targetTierDiscountConfiguration = null;
				let targetTier = -1;
				const tierCallback = (discountCode, discountConfiguration, tier, isError) => {
					calculateCartTotal(bannerLoader, discountConfiguration, totalPrice => {
						if (discountConfiguration.getSubtotalMinimumAsNumber() > totalPrice && targetTier === -1) {
							targetTierDiscountConfiguration = discountConfiguration;
							targetTierDiscountConfiguration = new DiscountConfiguration({
								...discountConfiguration.config,
								earnedCode: earnedDiscountConfiguration && earnedDiscountConfiguration.getCode(),
								earnedAmount: earnedDiscountConfiguration && earnedDiscountConfiguration.getAmount()
							});
							targetTier = currentTier;
						} else if (discountConfiguration.getSubtotalMinimumAsNumber() <= totalPrice) {
							earnedDiscountConfiguration = discountConfiguration;
						}
						if (currentTier < totalTiers) {
							bannerLoader.loadDiscountConfiguration(configuration.getBackupCode(++currentTier), tierCallback);
						} else if (currentTier === totalTiers) {
							const calculatedDiscountConfig = new DiscountConfiguration({
								...(targetTierDiscountConfiguration || discountConfiguration).config,
								earnedCode: earnedDiscountConfiguration && earnedDiscountConfiguration.getCode(),
								earnedAmount: earnedDiscountConfiguration && earnedDiscountConfiguration.getAmount()
							});
							const code =
								totalPrice >= discountConfiguration.getSubtotalMinimumAsNumber()
									? discountCode
									: (earnedDiscountConfiguration && earnedDiscountConfiguration.getCode()) || discountCode;

							const tierConfigs = [];
							for (let i = 0; i <= totalTiers; i++) {
								tierConfigs.push(bannerLoader.getDiscountConfiguration(configuration.getBackupCode(i)).config);
							}
							backupConfigurationCallback(
								code,
								calculatedDiscountConfig,
								targetTier === -1 ? currentTier - 1 : targetTier,
								tierConfigs,
								isError
							);
						}
					});
				};
				bannerLoader.loadDiscountConfiguration(configuration.getBackupCode(currentTier), tierCallback);
			} else if (configuration.getBackupCode()) {
				bannerLoader.loadDiscountConfiguration(configuration.getBackupCode(), backupConfigurationCallback);
			}
		} else if (configuration.getBackupType() !== 'discount') {
			backupConfigurationCallback(
				null,
				new DiscountConfiguration({
					subtotalMinimum: configuration.getBackupType() === 'freeShipping' ? configuration.getCustomFreeShippingMinimum() : 0,
					products: null
				}),
				0,
				[],
				false
			);
		}
	}
};

const cachedCollectionPromises = {};

const loadCollection = (bannerLoader, collectionId) => {
	if (!cachedCollectionPromises[collectionId]) {
		cachedCollectionPromises[collectionId] = new Promise(resolve => {
			const collectionCallback = (collectionId, collectionConfig) => {
				resolve(collectionConfig.getProducts().map(productId => ({ id: productId, type: 'product' })));
			};
			bannerLoader.loadCollectionConfiguration(collectionId, versionCache.getVersion(COLLECTIONS_VERSION_KEY), collectionCallback);
		});
	}
	return cachedCollectionPromises[collectionId];
};

const buildProductPromises = (bannerLoader, discountConfiguration) => {
	const productPromises = [];
	discountConfiguration.getEntitlements().forEach(product => {
		switch (product.type) {
			case 'product':
			case 'product_variant':
				productPromises.push(Promise.resolve(product));
				break;
			case 'collection':
				productPromises.push(loadCollection(bannerLoader, product.id));
				break;
			default:
				break;
		}
	});
	return productPromises;
};

const calculateCartTotal = (bannerLoader, discountConfiguration, callback) => {
	cartLoader
		.getCart()
		.then(cart => {
			if (!discountConfiguration.hasEntitlements() || cart.items.length === 0) {
				return Promise.resolve(cart.original_total_price);
			}
			const productPromises = buildProductPromises(bannerLoader, discountConfiguration);
			return new Promise(resolve => {
				Promise.all(productPromises)
					.then(products => {
						const combinedProducts = products.reduce((acc, current) => {
							return acc.concat(current);
						}, []);
						let totalPrice = 0;
						if (combinedProducts.length > 0) {
							cart.items.forEach(item => {
								for (let i = 0; i < combinedProducts.length; i++) {
									const product = combinedProducts[i];
									const itemId = product.type === 'product_variant' ? item.variant_id : item.product_id;
									if (Number(product.id) === Number(itemId)) {
										totalPrice += item.original_line_price;
										break;
									}
								}
							});
						}
						return totalPrice;
					})
					.catch(() => 0)
					.then(totalPrice => resolve(totalPrice));
			});
		})
		.catch(() => 0)
		.then(totalPrice => callback(totalPrice / 100));
};

const loadBanner = (bannerLoader, configuration, discountConfiguration, tier, tierConfigs, discount) => {
	const callback = totalPrice => {
		banner.load(
			configuration,
			discountConfiguration,
			totalPrice,
			tier,
			tierConfigs,
			discount.isNew(),
			customer.isCustomerJustSubscribed(),
			customer.isCustomerSubscribed()
		);
	};
	if (discountConfiguration.getSubtotalMinimumAsNumber() > 0) {
		calculateCartTotal(bannerLoader, discountConfiguration, callback);
	} else {
		callback(0);
	}
};

const initializeDiscount = (bannerLoader, account, conversion) => {
	bannerLoader.loadConfiguration(configuration => {
		const existingDiscount = configuration.isDynamicEnabled() ? new Discount() : null;
		if (conversion && existingDiscount && conversion.discount === existingDiscount.getCode()) {
			existingDiscount.markRedeemed();
		}
		const discountConfigurationCallback = (discountCode, discountConfiguration, tier, tierConfigs, isBackup, isError) => {
			const discount =
				existingDiscount && existingDiscount.getCode() === discountCode ? existingDiscount : new Discount(discountCode, !isBackup);
			if (!isError && discountConfiguration.isValid()) {
				const isDiscountBased = !(discountConfiguration.getType() === 'custom' || discountConfiguration.getType() === 'freeShipping');
				if (isDiscountBased && discount.isAvailable()) {
					if (conversion && conversion.discount === discount.getCode()) {
						discount.markRedeemed();
					}
					const autoApplyEnabled =
						(isBackup && !configuration.isCustomDisableAutoApply()) || (!isBackup && !configuration.isDisableAutoApply());
					if (autoApplyEnabled) {
						autoApply.applyCode(discount.getCode());
					}
				}
				loadBanner(bannerLoader, configuration, discountConfiguration, tier, tierConfigs, discount);
			} else {
				if (isError) {
					discount.markUnavailable();
				}
				if (!isBackup) {
					loadBackupDiscount(configuration, bannerLoader, discountConfigurationCallback);
				}
			}
		};
		if (existingDiscount && existingDiscount.isAvailable()) {
			bannerLoader.loadDiscountConfiguration(existingDiscount.getCode(), (discountCode, discountConfiguration, isError) => {
				discountConfigurationCallback(discountCode, discountConfiguration, 0, [], false, isError);
			});
		} else {
			loadBackupDiscount(configuration, bannerLoader, discountConfigurationCallback);
		}
	});
};

const initialize = () => {
	const account = new Account();
	const logger = new Logger(account);
	if (!account.isAccountAvailable()) {
		logger.log('No account identifier specified on script');
		return;
	}

	// const dispatcher = new Dispatcher(account, apiEndpoint);
	// dispatcher.start();

	// const emailStorage = new EmailStorage();
	// document.addEventListener(emailEvents.CAPTURED, event => emailStorage.storeEmail(event.detail.email));
	// const emailFinder = new EmailFinder();
	// emailFinder.findEmailCapturePoints();

	if (window.Shopify && window.Shopify.currency && document.body) {
		document.body.setAttribute('data-seguno-currency', window.Shopify.currency.active);
	}

	const conversionTracker = new ConversionTracker();
	const conversion = conversionTracker.checkForConversion();
	const bannerLoader = new BannerLoader(account, import.meta.env.VITE_CDN_ENDPOINT);

	initializeDiscount(bannerLoader, account, conversion);

	cartLoader.listen(() => {
		initializeDiscount(bannerLoader, account, conversion);
	});
};

initialize();
